import React, { useState, useRef, useEffect } from "react"
import axios from "axios";
import { Link } from "react-router-dom";
import {
    Card,
    CardBody,
    Col,
    Row,
    Input,
    Label,
    CardTitle,
    Button,
    Badge,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap"

import ToolkitProvider, {
    Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import withRouter from "../../components/Common/withRouter"
//i18next
import { connect } from "react-redux"
import showNotification, { showWarning } from "../../helpers/show_notificaton"
import { withTranslation } from "react-i18next"
import BootstrapTable from "react-bootstrap-table-next"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { postSubmitForm} from "../../helpers/backend_helper"
import cellEditFactory from "react-bootstrap-table2-editor"
import moment from "moment"
import preventValueChangeOnScroll from "../../helpers/PreventValueOnScroll"



const ViewUpdateBooking = props => {

    const { eventClick, bookingDetailsModal, setBookingDetailsModal, bookedPackageDetailscolumns, bookedPackageDetails, bookedItemDetails, bookedGameDetails, selectedCalendarEvent, setBookedGameDetails, setBookedItemsDetails, setBookedPackageDetails, loadAllBookings, keyChange, setKeyChange, showBookingDetails } = props;

    const [formatTime, setFormatTime] = useState("");
    const [endTime, setEndTime] = useState("");
    const [btnClass, setBtnClass] = useState('btn btn-info');
    const [ab, setAB] = useState({});
    const [cd, setCD] = useState({});
    const [itemVisible, setItemVisible] = useState(true);
    const [gameVisible, setGameVisible] = useState(false);
    const [startTimeUpdateInputVisibility, setStartTimeUpdateInputVisibility] = useState(false)
    const [startTimeExistingInputVisibility, setStartTimeExistingInputVisibility] = useState(true)
    const [updateStartTimePayload, setUpdateStartTimePayload] = useState({});
    const [confirmModal, setConfirmModal] = useState(false);
    const [updateFlag, setUpdateFlag] = useState(false)
    const [tracker, setTracker] = useState(false)
    const [enddTime, setEnddTime] = useState("")
    const [starttTime, setStarttTime] = useState("")
    const [startTimeTracker, setStartTimeTracker] = useState(false)

    // console.log(selectedCalendarEvent, "calender")

    // console.log(updateFlag, "update flag")
    // console.log(selectedCalendarEvent, "selected calendar event")
    // console.log(bookedGameDetails, 'game table details')
    // console.log(bookedGameDetails, "booked game details")

    // console.log(selectedCalendarEvent, "selected calendar event packages")
    // console.log(endTime,"end time")
    // console.log(formatTime,'format time')

    // USE-EFFECT -------------------------------------

    useEffect(() => {

        function formatTimeStamp(startTimeStamp, endTimeStamp) {

            const startDate = moment(startTimeStamp);
            const endDate = moment(endTimeStamp);
            const formattedStartDate = startDate.format('DD-MM-YYYY HH:mm');
            const formattedEndDate = endDate.format('DD-MM-YYYY HH:mm');
            setEndTime(prev => prev = formattedEndDate)
            setFormatTime(prev => prev = formattedStartDate)
        }

        formatTimeStamp(selectedCalendarEvent.start_time, selectedCalendarEvent.end_time);

        
    }, [bookingDetailsModal]);






    // -------------------------------------------------------------------

    // function handleRequestedGameDuration(e, rowIndex, row, editorProps) {
    //     const { value } = e.target;

    //     // if the changed value is equal to new value, which means no change, then return
    //     if (+value === +row.requested_duration) {
    //         editorProps.onUpdate(+value);   //this code written here just so that the input field gets closed onBlur
    //         return;
    //     }

    //     editorProps.onUpdate(+value);
    //     setUpdateFlag(prev => prev = true)

    //     let a = selectedCalendarEvent;

    //     a.package_details[0].game_details.forEach(item => {
    //         if (item.game_id === row.game_id) {

    //             if (+value < 30) {

    //                 // let sub_total = (row.price / 60) * 30;
    //                 // item.sub_total = sub_total.toFixed(2);
    //                 item.new_duration = row.duration - (+value)
    //             } else {

    //                 // item.sub_total = (+row.price / 60) * +value;
    //                 // item.sub_total = +(item.sub_total.toFixed(2));
    //                 item.new_duration = row.duration - (+value)
    //             }

    //             // console.log(item.game.price)
    //         }
    //     })
    // }
    const handleRequestedGameDuration = async (e, rowIndex, row, editorProps) => {
        const { value } = e.target;
        if (+value === +row.requested_duration) {
            editorProps.onUpdate(+value);
            return;
        }
        else if (+value >= row.requested_duration) {
            const requestDura = (value - row.requested_duration);
            const endTime = new Date(row.table_end_time);
            const updatedET = new Date(endTime.getTime() + requestDura * 60000);

            let payload = {
                start_time: row.table_end_time,
                end_time: updatedET,
                game_id: row.game_id,
            };

            let PieUrl = process.env.REACT_APP_BASEURL + "bookings/table_bookings_by_date";
            let res = await postSubmitForm(PieUrl, payload);
            if (res && res.status === 1 && res.data.some(item => item.table_number === row.table_number)) {
                editorProps.onUpdate(+value);
                const NewStartTime = new Date(row?.table_start_time)
                const durationInMinutes = row?.requested_duration
                const NewendTime = new Date(
                    NewStartTime.getTime() + durationInMinutes * 60000
                )
                setUpdateFlag(prev => prev = true)
                let a = selectedCalendarEvent;
                // console.log("BA",a.package_details[0].game_details);
                a.package_details[0].game_details.forEach(item => {
                    if (item.game_id === row.game_id) {
                        item.new_duration = row.duration - (+value)
                        item.table_end_time = NewendTime.toISOString()
                    }
                })
                // console.log(a.package_details[0].game_details,"AFTER");
                findMaxEndTime(bookedGameDetails);
            } else {
                showWarning("Table is busy", "warning")
                editorProps.onUpdate()
            }
        }
        else if (+value <= row.requested_duration) {
            editorProps.onUpdate(+value);
            const NewStartTime = new Date(row?.table_start_time)
            const durationInMinutes = row?.requested_duration
            const NewendTime = new Date(
                NewStartTime.getTime() + durationInMinutes * 60000
            )
            setUpdateFlag(prev => prev = true)
            let a = selectedCalendarEvent;
            a.package_details[0].game_details.forEach(item => {
                if (item.game_id === row.game_id) {
                    item.new_duration = row.duration - (+value)
                    item.table_end_time = NewendTime.toISOString()
                }
            })
            findMaxEndTime(bookedGameDetails);
        } else {
        }
    };

    const handlePKGStartTime = async (e, rowIndex, row, editorProps, value) => {
        const { value: selectedValue } = e.target
        if (!selectedValue || isNaN(Date.parse(selectedValue))) {
            return
        }
        if (new Date(selectedValue) < new Date(row.table_start_time)) {
            console.log(1);
            const selectedTime = moment(selectedValue)
            const oldStartTime = moment(row.table_start_time)
            const differenceMinutes = oldStartTime.diff(selectedTime, 'minutes');
            if (differenceMinutes > row.requested_duration) {
                const NewStartTime = new Date(selectedValue)
                const PLEndTime = new Date(NewStartTime.getTime() + row.requested_duration * 60000)
                let payload = {
                    start_time: NewStartTime,
                    end_time: PLEndTime,
                    game_id: row.game_id,
                }
                let PieUrl = process.env.REACT_APP_BASEURL + "bookings/table_bookings_by_date"
                let res = await postSubmitForm(PieUrl, payload)
                if (res && res.status === 1 && res.data.some(item => item.table_number === row.table_number)) {
                    setUpdateFlag(prev => prev = true)
                    let a = selectedCalendarEvent;
                    editorProps.onUpdate(selectedValue)
                    a.package_details[0].game_details.forEach(item => {
                        if (item.game_id === row.game_id) {
                            item.table_start_time = NewStartTime
                            item.table_end_time = PLEndTime
                        }
                    })    
                    findMinimumStartTimePKG()
                    findMaxEndTime(bookedGameDetails);
                } else {
                    showWarning("Table is busy", "warning")
                }
            } else {
                const selectedTime = moment(selectedValue)
                const NewStartTime = new Date(selectedValue)
                const oldStartTime = moment(row.table_start_time)
                const differenceMinutes = oldStartTime.diff(selectedTime, 'minutes');
                const PLEndTime = new Date(NewStartTime.getTime() + differenceMinutes * 60000)
                const UpdatedET = new Date(NewStartTime.getTime() + row.requested_duration * 60000)
                let payload = {
                    start_time: selectedTime,
                    end_time: PLEndTime,
                    game_id: row.game_id,
                }
                let PieUrl = process.env.REACT_APP_BASEURL + "bookings/table_bookings_by_date"
                let res = await postSubmitForm(PieUrl, payload)
                if (res && res.status === 1 && res.data.some(item => item.table_number === row.table_number)) {
                    setUpdateFlag(prev => prev = true)
                    let a = selectedCalendarEvent;
                    editorProps.onUpdate(selectedValue)
                    a.package_details[0].game_details.forEach(item => {
                        if (item.game_id === row.game_id) {
                            item.table_start_time = new Date(selectedValue).toISOString()
                            item.table_end_time = UpdatedET
                        }
                    })
                    findMinimumStartTimePKG()                   
                    findMaxEndTime(bookedGameDetails);

                } else {
                    showWarning("Table is busy", "warning")
                }
            }

        }
        else if (new Date(selectedValue) > new Date(row.table_start_time)) {
            // console.log(2);
            const selectedTime = moment(selectedValue)
            const oldStartTime = new Date(row.table_start_time)
            const differenceMinutes = selectedTime.diff(oldStartTime, 'minutes');
            const newStartTime = new Date(row.table_end_time)
            const NewendTime = new Date(newStartTime.getTime() + differenceMinutes * 60000)
            if (differenceMinutes > row.requested_duration) {
                const NewStartTime = new Date(selectedValue)
                const PLEndTime = new Date(NewStartTime.getTime() + row.requested_duration * 60000)
                let payload = {
                    start_time: NewStartTime,
                    end_time: PLEndTime,
                    game_id: row.game_id,
                }
                let PieUrl = process.env.REACT_APP_BASEURL + "bookings/table_bookings_by_date"
                let res = await postSubmitForm(PieUrl, payload)
                if (res && res.status === 1 && res.data.some(item => item.table_number === row.table_number)) {
                    setUpdateFlag(prev => prev = true)
                    let a = selectedCalendarEvent;
                    editorProps.onUpdate(selectedValue)
                    a.package_details[0].game_details.forEach(item => {
                        if (item.game_id === row.game_id) {
                            item.table_start_time = new Date(NewStartTime)
                            item.table_end_time = PLEndTime
                        }
                    })    
                    findMaxEndTime(bookedGameDetails);
                    findMinimumStartTimePKG();
                } else {
                    showWarning("Table is busy", "warning")
                }
            } else {
                // console.log("Hiii");
                let payload = {
                    start_time: newStartTime,
                    end_time: NewendTime,
                    game_id: row.game_id,
                }
                let PieUrl = process.env.REACT_APP_BASEURL + "bookings/table_bookings_by_date"
                let res = await postSubmitForm(PieUrl, payload)
                if (res && res.status === 1 && res.data.some(item => item.table_number === row.table_number)) {
                    setUpdateFlag(prev => prev = true)
                    let a = selectedCalendarEvent;
                    editorProps.onUpdate(selectedValue)
                    a.package_details[0].game_details.forEach(item => {
                        if (item.game_id === row.game_id) {
                            item.table_start_time = new Date(selectedValue).toISOString()
                            item.table_end_time = new Date(NewendTime).toISOString()
                        }
                    })    
                    // console.log(bookedGameDetails,'BG');
                    // console.log(selectedCalendarEvent,'Cal');
                    findMinimumStartTimePKG()
                    findMaxEndTime(bookedGameDetails);
                } else {
                    showWarning("Table is busy", "warning")
                }
            }

        }
        else if (new Date(selectedValue) > new Date(row.table_end_time)) {
            console.log(3);
            const durationInMinutes = row.requested_duration
            const NewstartTime = new Date(selectedValue)
            const NewendTime = new Date(NewstartTime.getTime() + durationInMinutes * 60000)
            let payload = {
                start_time: NewstartTime,
                end_time: NewendTime,
                game_id: row.game_id,
            }
            let PieUrl = process.env.REACT_APP_BASEURL + "bookings/table_bookings_by_date"
            let res = await postSubmitForm(PieUrl, payload)
            if (res && res.status === 1 && res.data.some(item => item.table_number === row.table_number)) {
                setUpdateFlag(prev => prev = true)
                let a = selectedCalendarEvent;
                editorProps.onUpdate(selectedValue)
                a.package_details[0].game_details.forEach(item => {
                    if (item.game_id === row.game_id) {
                        item.table_start_time = NewstartTime
                        item.table_end_time = NewendTime
                    }
                })    
                findMinimumStartTimePKG()
                findMaxEndTime(bookedGameDetails);

            } else {
                showWarning("Table is busy", "warning")
            }
        }
    }

    const customerDetailsColumn = [
        {
            dataField: "_id",
            hidden: true,
        },
        {
            dataField: "_id",
            formatter: (cell, row, rowIndex) => {
                // const rowNumber = (+pageNumber - 1) * +pageSize + (+rowIndex + 1);
                const rowNumber = rowIndex + 1
                return rowNumber
            },
            text: props.t("S.N"),
            headerStyle: (colum, colIndex) => {
                return { width: "1%" }
            },
            editable: false
        },
        {
            text: props.t("Name"),
            dataField: "name",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { width: "5%" }
            },
            editable: false
        },
        {
            text: props.t("Mobile"),
            dataField: "mobile",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { width: "5%" }
            },
            editable: false
        },
        {
            text: props.t("Customer Type"),
            dataField: "customer_type",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { width: "5%" }
            },
            editable: false
        },

    ]

    // previous package game columns for table
    const addPackageGameDetailsColumn = [
        {
            dataField: "game_id",
            hidden: true,
        },
        {
            dataField: "game_id",
            formatter: (cell, row, rowIndex) => {
                // const rowNumber = (+pageNumber - 1) * +pageSize + (+rowIndex + 1);
                const rowNumber = rowIndex + 1
                return rowNumber
            },
            text: props.t("S.N"),
            headerStyle: (colum, colIndex) => {
                return { width: "1%" }
            },
            editable: false
        },
        {
            text: props.t("Game"),
            formatter: showPhotoFormatterPrevGames,
            dataField: "game.image",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { width: "5%" }
            },
            editable: false
        },
        {
            dataField: "table_start_time",
            text: "Start Time",
            formatter: (cell, row) => {
                return moment(row?.table_start_time).format("HH:mm")
            },
            headerStyle: (colum, colIndex) => {
                return { width: "7%" }
            },
            editorRenderer: (
                editorProps,
                value,
                row,
                column,
                rowIndex,
                columnIndex
            ) => {
                if (row.table_start_time) {
                    return (
                        <input
                            style={{
                                width: "100px",
                                border: "1px solid lightGray",
                                padding: "5px",
                                borderRadius: "5px",
                                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                            }}
                            type="datetime-local"
                            defaultValue={value}
                            onBlur={e => {
                                handlePKGStartTime(e, rowIndex, row, editorProps, value)
                            }}
                            onFocus={e => e.target.select()}
                            autoFocus
                        />
                    )
                } else {
                    return <div style={{ color: "red" }}>Not Available</div>
                }
            },
            editable: true,
        },
        {
            text: props.t("Requested Duration"),
            dataField: "requested_duration",
            formatter: (cell, row) => {
                return <p style={{ color: "#E57373", fontWeight: 600, width: "50px" }} >{`${row.requested_duration} min`}</p>;
            },
            sort: false,
            validator: (newValue, row, column) => {
                if (isNaN(newValue)) {
                    return {
                        valid: false,
                        message: "Only numbers are allowed.",
                    };
                }
                if (newValue % 1 != 0) {
                    return {
                        valid: false,
                        message: "Decimals not allowed.",
                    };
                } if (newValue > row.new_duration + row.duration) {
                    return {
                        valid: false,
                        message: "Maximum Duration reached",
                    };
                }
                return true;
            },
            editorRenderer: (
                editorProps,
                value,
                row,
                column,
                rowIndex,
                columnIndex
            ) => (
                <input
                    onWheel={preventValueChangeOnScroll}
                    // ref={inputRef}
                    style={{
                        width: "50px",
                        height: "40px",
                        border: "1px solid lightGray",
                        padding: "5px",
                        borderRadius: "5px",
                        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                    }}
                    type="number"
                    defaultValue={value}
                    onChange={(e) => {
                        setTracker(true)
                    }}
                    onBlur={e => {
                        handleRequestedGameDuration(e, rowIndex, row, editorProps);
                    }}
                    onFocus={e => e.target.select()} // Retain focus on input field
                    autoFocus
                />
            ),
            headerStyle: (colum, colIndex) => {
                return { width: "5%" }
            },
            editable: true
        },
        {
            dataField: "table_end_time",
            text: "End Time",
            formatter: (cell, row) => {
                return moment(row?.table_end_time).format("HH:mm")
            },
            headerStyle: (colum, colIndex) => {
                return { width: "7%" }
            },
            editable: false,
        },
        {
            text: props.t("Available Duration"),
            dataField: "new_duration",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { width: "5%" }
            },
            editable: false
        },
        {
            text: props.t("Total Duration"),
            dataField: "duration",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { width: "5%" }
            },
            editable: false
        },
    ]
    //   ------------------------------------------------------------------------------------


    function handleNewQuantityUnitChange(e, rowIndex, row, editorProps) {
        const { value } = e.target;
        if (+value === +row.quantity.in_unit) {
            editorProps.onUpdate(+value);         //this code written here just so that the input field gets closed onBlur
            return;
        }
        editorProps.onUpdate(+value);
        setUpdateFlag(prev => prev = true)
        let a = selectedCalendarEvent.package_details[0].item_details;
        a.forEach(item => {
            if (item.item_id === row.item_id) {
                if (row.sub_unit === "" || row.sub_unit === null) {
                    const formula = item.formula || 1;
                    const availableInSubUnits = (item.available.in_unit * formula) + item.available.in_sub_unit;
                    const quantityInSubUnits = (item.quantity.in_unit * formula) + item.quantity.in_sub_unit;
                    const newAvailableInSubUnits = availableInSubUnits - quantityInSubUnits;
                    const newAvailableInUnits = Math.floor(newAvailableInSubUnits / formula);
                    const newAvailableSubUnits = newAvailableInSubUnits % formula;
                    row.new_available.in_unit = newAvailableInUnits;
                    row.new_available.in_sub_unit = newAvailableSubUnits;
                } else {


                    const formula = item.formula || 1;

                    // Convert available to sub-units
                    const availableInSubUnits = (item.available.in_unit * formula) + item.available.in_sub_unit;

                    // Convert quantity to sub-units
                    const quantityInSubUnits = (item.quantity.in_unit * formula) + item.quantity.in_sub_unit;

                    // Calculate the new available sub-units
                    const newAvailableInSubUnits = availableInSubUnits - quantityInSubUnits;

                    // Convert new available sub-units back to units and sub-units
                    const newAvailableInUnits = Math.floor(newAvailableInSubUnits / formula);
                    const newAvailableSubUnits = newAvailableInSubUnits % formula;


                    row.new_available.in_unit = newAvailableInUnits;
                    row.new_available.in_sub_unit = newAvailableSubUnits;
                }
            }
        });

        // setSelectedPreviousPackage(prev => { prev = { ...a }; return prev });

    }

    function handleNewQuantitySubUnitChange(e, rowIndex, row, editorProps) {
        const { value } = e.target;
        if (+value === +row.quantity.in_sub_unit) {
            editorProps.onUpdate(+value);         //this code written here just so that the input field gets closed onBlur
            return;
        }

        if (+value > Number(((+row.formula) * (+row.available.in_unit)) + (+row.available.in_sub_unit))) {

            editorProps.onUpdate(+value);
            return;
        }

        let subUnits = 0;
        let units = 0;

        if (+value >= Number(row.formula)) {

            subUnits = (+value) % Number(row.formula);
            units = Math.floor((+value) / Number(row.formula))

        } else {
            subUnits = +value;
        }

        editorProps.onUpdate(subUnits);
        setUpdateFlag(prev => prev = true)

        let a = selectedCalendarEvent.package_details[0].item_details;

        a.forEach(item => {
            if (item.item_id === row.item_id) {
                if (+value >= (+row.formula) && ((item.quantity.in_unit + units) <= row.available.in_unit)) {
                    item.quantity.in_unit += units;
                }

                const formula = item.formula || 1;

                // Convert available to sub-units
                const availableInSubUnits = (item.available.in_unit * formula) + item.available.in_sub_unit;

                // Convert quantity to sub-units
                const quantityInSubUnits = (item.quantity.in_unit * formula) + item.quantity.in_sub_unit;

                // Calculate the new available sub-units
                const newAvailableInSubUnits = availableInSubUnits - quantityInSubUnits;

                // Convert new available sub-units back to units and sub-units
                const newAvailableInUnits = Math.floor(newAvailableInSubUnits / formula);
                const newAvailableSubUnits = newAvailableInSubUnits % formula;

                // console.log("u:", newAvailableInUnits, "sb:", newAvailableSubUnits)

                row.new_available.in_unit = newAvailableInUnits;
                row.new_available.in_sub_unit = newAvailableSubUnits;
            }
        })
    }

    // previous package items columns for table 
    const addPackageItemDetailsColumn = [
        {
            dataField: "item_id",
            hidden: true,
        },
        {
            dataField: "item_id",
            formatter: (cell, row, rowIndex) => {
                // const rowNumber = (+pageNumber - 1) * +pageSize + (+rowIndex + 1);
                const rowNumber = rowIndex + 1
                return rowNumber
            },
            text: props.t("S.N"),
            headerStyle: (colum, colIndex) => {
                return { width: "1%" }
            },
            editable: false
        },
        {
            text: props.t("Image"),
            formatter: showImagePhotoFormatter,
            dataField: "image_url",
            //sort: false,
            headerStyle: (colum, colIndex) => {
                return { width: "5%" }
            },
            editable: false
        },
        {
            text: props.t("Name"),
            dataField: "name",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { width: "5%" }
            },
            editable: false
        },
        {
            text: props.t("Available Quantity(in unit)"),
            dataField: "available.in_unit",
            formatter: (cell, row) => {
                return <p >{`${row.available.in_unit} ${row.unit}`}</p>;
            },
            sort: false,
            // formatter: (cell, row) => {
            //     return row.available.in_unit + " " + row.unit
            // },
            headerStyle: (colum, colIndex) => {
                return { width: "5%" }
            },
            editable: false
        },
        {
            text: props.t("Available Quantity(in sub unit)"),
            dataField: "available.in_sub_unit",
            formatter: (cell, row) => {
                return <p>{(row.sub_unit === null || row.sub_unit === "") ? <Badge className="badge-soft-danger" >No Sub-Unit</Badge> : `${row.available.in_sub_unit} ${row.sub_unit}`}</p>;
            },
            sort: false,
            // formatter: (cell, row) => {
            //     return row.available.in_sub_unit + " " + row.sub_unit
            // },
            headerStyle: (colum, colIndex) => {
                return { width: "5%" }
            },
            editable: false
        },
        {
            text: props.t("Qty.(in unit)"),
            dataField: "quantity.in_unit",
            formatter: (cell, row) => {
                return <p style={{ color: "#E57373", fontWeight: 600, width: "50px" }} >{`${row.quantity.in_unit} ${row.unit}`}</p>;
            },
            sort: false,
            validator: (newValue, row, column) => {
                const isHouseholdItem = row.is_household_item === true;
                if (!isHouseholdItem) {
                  if (+newValue > Number(row.available.in_unit)) {
                    return {
                      valid: false,
                      message: "Cannot exceed available units",
                    };
                  }
              
                  if (+newValue < 0) {
                    return {
                      valid: false,
                      message: "Cannot be a negative value",
                    };
                  }
                  if (isNaN(newValue)) {
                    return {
                      valid: false,
                      message: "Only numbers are allowed.",
                    };
                  }
                  if (newValue % 1 != 0) {
                    return {
                      valid: false,
                      message: "Decimals not allowed.",
                    };
                  }
                }
                return true;
            }, 
            editorRenderer: (
                editorProps,
                value,
                row,
                column,
                rowIndex,
                columnIndex
            ) => (
                <input
                    onWheel={preventValueChangeOnScroll}
                    // ref={inputRef}
                    style={{
                        width: "50px",
                        height: "40px",
                        border: "1px solid lightGray",
                        padding: "5px",
                        borderRadius: "5px",
                        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                    }}
                    type="number"
                    defaultValue={value}

                    onBlur={e => {
                        handleNewQuantityUnitChange(e, rowIndex, row, editorProps);

                    }}
                    onFocus={e => e.target.select()} // Retain focus on input field
                    autoFocus
                />
            ),
            headerStyle: (colum, colIndex) => {
                return { width: "5%" }
            },
            editable: true
        },
        {
            text: props.t("Qty.(in sub unit)"),
            dataField: "quantity.in_sub_unit",
            formatter: (cell, row) => {
                return <p style={{ color: "#E57373", fontWeight: 600, width: "50px" }} >{(row.sub_unit === "" || row.sub_unit === null) ? <i className="fa fa-ban" /> : `${row.quantity.in_sub_unit} ${row.sub_unit}`}</p>;
            },
            sort: false,
            validator: (newValue, row, column) => {
                const isHouseholdItem = row.is_household_item === true;
               if(!isHouseholdItem){
                if (     +newValue >     Number(       +row.formula * +row.available.in_unit + +row.available.in_sub_unit     )   ) {
                  return {
                    valid: false,
                    message: "Cannot exceed available quantity",
                  }
                }
                if (
                  +newValue >
                  Number(
                    (+row.available.in_unit - +row.quantity.in_unit) * +row.formula +
                      +row.available.in_sub_unit
                  )
                ) {
                  return {
                    valid: false,
                    message: "Cannot exceed more",
                  }
                }
                if (+newValue < 0) {
                  return {
                    valid: false,
                    message: "Cannot be a negative value",
                  }
                }
                if (isNaN(newValue)) {
                  return {
                    valid: false,
                    message: "Only numbers are allowed.",
                  }
                }
                if (newValue % 1 != 0) {
                  return {
                    valid: false,
                    message: "Decimals not allowed.",
                  }
                }
                return true
               }
              },
            editorRenderer: (
                editorProps,
                value,
                row,
                column,
                rowIndex,
                columnIndex
            ) => (
                <input
                    onWheel={preventValueChangeOnScroll}
                    // ref={inputRef}
                    style={{
                        width: "50px",
                        height: "40px",
                        border: "1px solid lightGray",
                        padding: "5px",
                        borderRadius: "5px",
                        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                    }}
                    type="number"
                    defaultValue={value}
                    // onChange={(e) => { handleQuantityChange(e, rowIndex, row, editorProps);  inputRef.current.blur(); }}
                    onBlur={e => {
                        handleNewQuantitySubUnitChange(e, rowIndex, row, editorProps);
                        // editorProps.onUpdate(+e.target.value)

                        // setEnterQtyArr(prev => { prev = { ...prev, [row._id]: row.quantity.in_unit === 0 ? false : true } })
                    }}
                    onFocus={e => e.target.select()} // Retain focus on input field
                    autoFocus
                />
            ),
            headerStyle: (colum, colIndex) => {
                return { width: "5%" }
            },
            editable: (cell, row) => ((row.sub_unit === "" || row.sub_unit === null) ? false : true)
        },

    ]

    function showPhotoFormatter(cell, row) {
        return (
            <>
                <img
                    src={row.game.image_url || row.game.image}
                    alt={row.game.name}
                    className="avatar-md rounded-circle img-thumbnail"
                />
                <br />
                {row.table_number} {`(${row.game?.name})`}
                <span
                    style={{ cursor: "pointer" }}
                    className="font-size-12 badge-soft-info badge badge-success badge-pill"
                ></span>
            </>
        )
    }

    function showPhotoFormatterPrevGames(cell, row) {
        return (
            <>
                <img
                    src={row.image_url || row.image}
                    alt={row.name}
                    className="avatar-md rounded-circle img-thumbnail"
                />
                <br />
                {row.name} &nbsp; {`(${row?.table_number})`}
            </>
        )
    }


    function showImagePhotoFormatter(cell, row) {
        return (
            <>
                <img
                    src={row.image_url || row.image + "?" + new Date() + "=" + new Date()}
                    alt={row.name}
                    className="avatar-md rounded-circle img-thumbnail"
                />
                <span
                    style={{ cursor: "pointer" }}
                    className="font-size-12 badge-soft-info badge badge-success badge-pill"
                ></span>
            </>
        )
    }

    // -----------------------------------------------------------------------------------------------

    const handleQuantityChange = (e, rowIndex, row, editorProps) => {
        const { value } = e.target;
        // const updatedData = { ...row };
        // const rowData = updatedData;
        // rowData.quantity.in_unit = +value;

        // if the changed value is equal to new value, which means no change, then return
        if (+value === +row.quantity.in_unit) {
            editorProps.onUpdate(+value);         //this code written here just so that the input field gets closed onBlur
            return;
        }

        editorProps.onUpdate(+value);
        setUpdateFlag(prev => prev = true)

        if (cd[row._id] === undefined) {
            setCD(prev => ({ ...prev, [row._id]: row.price }));
        }

        let a = bookedItemDetails;
        let price = cd[row._id] ? cd[row._id] : row.price;

        // console.log(row, "selected row");
        // console.log(a)

        a.forEach(item => {
            if (item.item_id === row.item_id) {
                if (row.sub_unit === "" || row.sub_unit === null) {
                    item.sub_total = newValue * price;
                } else {
                    item.sub_total = ((+value * Number(row.formula) + Number(row.quantity.in_sub_unit)) * (price / row.formula));
                    item.sub_total = +(item.sub_total.toFixed(2))
                }
            }
        });

        setBookedItemsDetails(prev => { prev = [...a]; return prev });
        // handleItemSelect(a);
    };

    const handleSubUnitQuantityChange = (e, rowIndex, row, editorProps) => {

        const { value } = e.target;
        // const updatedData = { ...row };
        // const rowData = updatedData;
        // rowData.quantity.in_sub_unit = +value;

        // if the changed value is equal to new value, which means no change, then return
        if (+value === +row.quantity.in_sub_unit) {
            editorProps.onUpdate(+value);         //this code written here just so that the input field gets closed onBlur
            return;
        }

        if (+value > Number(((+row.formula) * (+row.available.in_unit)) + (+row.available.in_sub_unit))) {

            editorProps.onUpdate(+value);
            return;
        }

        let subUnits = 0;
        let units = 0;
        if (+value >= Number(row.formula)) {
            subUnits = (+value) % Number(row.formula);
            units = Math.floor((+value) / Number(row.formula))
        } else {
            subUnits = +value;
        }
        editorProps.onUpdate(subUnits);
        setUpdateFlag(prev => prev = true)
        if (cd[row._id] === undefined) {
            setCD(prev => ({ ...prev, [row._id]: row.price }));
        }
        let a = bookedItemDetails;
        let price = cd[row._id] ? cd[row._id] : row.price;
        a.forEach(item => {
            if (item.item_id === row.item_id) {
                if (+value >= (+row.formula) && ((item.quantity.in_unit + units) <= row.available.in_unit)) {
                    item.quantity.in_unit += units;
                }

                item.sub_total = (+value + (Number(row.quantity.in_unit) * Number(row.formula))) * (price / row.formula);
                item.sub_total = +(item.sub_total.toFixed(2))
            }
        })
        setBookedItemsDetails(prev => { prev = [...a]; return prev })
        // handleItemSelect(a);
    };

    const findMinimumStartTimePKG = () => {
        setStartTimeTracker(true);
        let gamStartTime = null;

        selectedCalendarEvent?.package_details[0]?.game_details.forEach((table) => {
            const tableStartTime = new Date(table.table_start_time);
            if (!gamStartTime || tableStartTime < gamStartTime) {
                gamStartTime = tableStartTime;
                const ForPayload = new Date(gamStartTime).toISOString();
                const ForUI = moment(gamStartTime).format("YYYY-MM-DD HH:mm");
                setStarttTime(ForPayload)
                setFormatTime(ForUI);
            }
        });
    }; 
   
    const findMinimumStartTime = (gameTables) => {
        setStartTimeTracker(true);
        let gamStartTime = null;
        let pkgStartTime = null;

        if (selectedCalendarEvent?.package_details[0]?.game_details?.length > 0) {
            selectedCalendarEvent?.package_details[0]?.game_details.forEach((table) => {
                const tableStartTime = new Date(table.table_start_time);
                if (!pkgStartTime || tableStartTime < pkgStartTime) {
                    pkgStartTime = tableStartTime;
                }
            });
        }

        gameTables.forEach((table) => {
            const tableStartTime = new Date(table.game.table_start_time);
            if (!gamStartTime || tableStartTime < gamStartTime) {
                gamStartTime = tableStartTime;
            }
        });

        if (pkgStartTime && pkgStartTime < gamStartTime) {
            const ForPayload = new Date(pkgStartTime).toISOString();
            const ForUI = moment(pkgStartTime).format("YYYY-MM-DD HH:mm");
            setStarttTime(ForPayload)
            setFormatTime(ForUI)
        } else {
            const ForPayload = new Date(gamStartTime).toISOString();
            const ForUI = moment(gamStartTime).format("YYYY-MM-DD HH:mm");
            setStarttTime(ForPayload)
            setFormatTime(ForUI);
        }
    }; 

    const findMaxEndTime = (gameTables) => {
        setTracker(true);
        let gamEndTime = null;
        let pkgEndTime = null;

        if (selectedCalendarEvent?.package_details[0]?.game_details?.length > 0) {
            selectedCalendarEvent?.package_details[0]?.game_details.forEach((table) => {
                const tableEndTime = new Date(table.table_end_time);
                if (!pkgEndTime || tableEndTime > pkgEndTime) {
                    pkgEndTime = tableEndTime;
                }
            });
        }

        gameTables.forEach((table) => {
            const tableEndTime = new Date(table.game.table_end_time);
            if (!gamEndTime || tableEndTime > gamEndTime) {
                gamEndTime = tableEndTime;
            }
        });

        if (pkgEndTime && pkgEndTime > gamEndTime) {
            const ForPayload = new Date(pkgEndTime).toISOString();
            const ForUI = moment(pkgEndTime).format("YYYY-MM-DD HH:mm");
            setEnddTime(ForPayload)
            setEndTime(ForUI)
        } else {
            const ForPayload = new Date(gamEndTime).toISOString();
            const ForUI = moment(gamEndTime).format("YYYY-MM-DD HH:mm");
            setEnddTime(ForPayload)
            setEndTime(ForUI);
        }
    };
    const handleStartTime = async (e, rowIndex, row, editorProps, value) => {
      console.log(row,"ROW");
        const { value: selectedValue } = e.target
        if (!selectedValue || isNaN(Date.parse(selectedValue))) {
            return
        }
        if (new Date(selectedValue) < new Date(row.game.table_start_time)) {
            console.log(1);
            const selectedTime = moment(selectedValue)
            const oldStartTime = moment(row.game.table_start_time)
            const differenceMinutes = oldStartTime.diff(selectedTime, 'minutes');
            if (differenceMinutes > row.game.duration) {
                const NewStartTime = new Date(selectedValue)
                const PLEndTime = new Date(NewStartTime.getTime() + row.game.duration * 60000)
                let payload = {
                    start_time: NewStartTime,
                    end_time: PLEndTime,
                    game_id: row.game.game_id,
                }
                let PieUrl = process.env.REACT_APP_BASEURL + "bookings/table_bookings_by_date"
                let res = await postSubmitForm(PieUrl, payload)
                if (res && res.status === 1 && res.data.some(item => item.table_number === row.table_number)) {
                    setUpdateFlag(prev => prev = true)
                    editorProps.onUpdate(selectedValue)
                    const updatedTableData = { ...row }
                    const rowData = updatedTableData
                    rowData.game.table_start_time = NewStartTime
                    rowData.game.table_end_time = PLEndTime
                    findMinimumStartTime(bookedGameDetails);
                    findMaxEndTime(bookedGameDetails);
                } else {
                    showWarning("Table is busy", "warning")
                }
            } else {
                const selectedTime = moment(selectedValue)
                const NewStartTime = new Date(selectedValue)
                const oldStartTime = moment(row.game.table_start_time)
                const differenceMinutes = oldStartTime.diff(selectedTime, 'minutes');
                const PLEndTime = new Date(NewStartTime.getTime() + differenceMinutes * 60000)
                const UpdatedET = new Date(NewStartTime.getTime() + row.game.duration * 60000)
                let payload = {
                    start_time: selectedTime,
                    end_time: PLEndTime,
                    game_id: row.game.game_id,
                }
                let PieUrl = process.env.REACT_APP_BASEURL + "bookings/table_bookings_by_date"
                let res = await postSubmitForm(PieUrl, payload)
                if (res && res.status === 1 && res.data.some(item => item.table_number === row.table_number)) {
                    setUpdateFlag(prev => prev = true)
                    const updatedTableData = { ...row }
                    const rowData = updatedTableData
                    editorProps.onUpdate(selectedValue)
                    rowData.game.table_start_time = new Date(selectedValue).toISOString()
                    rowData.game.table_end_time = UpdatedET
                    findMinimumStartTime(bookedGameDetails);
                    findMaxEndTime(bookedGameDetails);

                } else {
                    showWarning("Table is busy", "warning")
                }
            }

        }
        else if (new Date(selectedValue) > new Date(row.game.table_start_time)) {
            console.log(2);
            const selectedTime = moment(selectedValue)
            const oldStartTime = new Date(row.game.table_start_time)
            const differenceMinutes = selectedTime.diff(oldStartTime, 'minutes');
            const newStartTime = new Date(row.game.table_end_time)
            const NewendTime = new Date(newStartTime.getTime() + differenceMinutes * 60000)
            if (differenceMinutes > row.game.duration) {
                const NewStartTime = new Date(selectedValue)
                const PLEndTime = new Date(NewStartTime.getTime() + row.game.duration * 60000)
                let payload = {
                    start_time: NewStartTime,
                    end_time: PLEndTime,
                    game_id: row.game.game_id,
                }
                let PieUrl = process.env.REACT_APP_BASEURL + "bookings/table_bookings_by_date"
                let res = await postSubmitForm(PieUrl, payload)
                if (res && res.status === 1 && res.data.some(item => item.table_number === row.table_number)) {
                    setUpdateFlag(prev => prev = true)
                    editorProps.onUpdate(selectedValue)
                    const updatedTableData = { ...row }
                    const rowData = updatedTableData
                    rowData.game.table_start_time = NewStartTime
                    rowData.game.table_end_time = PLEndTime
                    findMinimumStartTime(bookedGameDetails);
                    findMaxEndTime(bookedGameDetails);
                } else {
                    showWarning("Table is busy", "warning")
                }
            } else{
                let payload = {
                    start_time: newStartTime,
                    end_time: NewendTime,
                    game_id: row.game.game_id,
                }
                let PieUrl = process.env.REACT_APP_BASEURL + "bookings/table_bookings_by_date"
                let res = await postSubmitForm(PieUrl, payload)
                if (res && res.status === 1 && res.data.some(item => item.table_number === row.table_number)) {
                    setUpdateFlag(prev => prev = true)
                    editorProps.onUpdate(selectedValue)
                    const updatedTableData = { ...row }
                    const rowData = updatedTableData
                    rowData.game.table_start_time = new Date(selectedValue).toISOString()
                    rowData.game.table_end_time = NewendTime
                    findMinimumStartTime(bookedGameDetails);
                    findMaxEndTime(bookedGameDetails);
console.log(row,"ROW");
                } else {
                    showWarning("Table is busy", "warning")
                }
            }
           
        }
        else if (new Date(selectedValue) > new Date(row.game.table_end_time)) {
            console.log(3);
            const durationInMinutes = row.game.duration
            const NewstartTime = new Date(selectedValue)
            const NewendTime = new Date(NewstartTime.getTime() + durationInMinutes * 60000)
            let payload = {
                start_time: NewstartTime,
                end_time: NewendTime,
                game_id: row.game.game_id,
            }
            let PieUrl = process.env.REACT_APP_BASEURL + "bookings/table_bookings_by_date"
            let res = await postSubmitForm(PieUrl, payload)
            if (res && res.status === 1 && res.data.some(item => item.table_number === row.table_number)) {
                const updatedTableData = { ...row }
                const rowData = updatedTableData
                rowData.game.table_start_time = NewstartTime
                rowData.game.table_end_time = NewendTime
                editorProps.onUpdate(selectedValue)
                setUpdateFlag(prev => prev = true)
                findMinimumStartTime(bookedGameDetails);
                findMaxEndTime(bookedGameDetails);

            } else {
                showWarning("Table is busy", "warning")
            }
        }
    }    
    const handleDurationChange = async (e, rowIndex, row, editorProps) => {
        const { value } = e.target;
        let price = 0;
        if (selectedCalendarEvent?.customer_details?.customer_type === "Friend") {
            price = row.game.price_for_friend;
        } else if (selectedCalendarEvent?.customer_details?.customer_type === "Regular") {
            price = row.game.price_for_regular
        } else if (selectedCalendarEvent?.customer_details?.customer_type === "VIP") {
            price = 0;
        }
        if (+value === +row.game.duration) {
            editorProps.onUpdate(+value);
            return;
        }
        else if (+value >= row.game.duration) {

            const requestDura = (value - row.game.duration);
            const endTime = new Date(row.game.table_end_time);
            const updatedET = new Date(endTime.getTime() + requestDura * 60000);

            let payload = {
                start_time: row.game.table_end_time,
                end_time: updatedET,
                game_id: row.game.game_id,
            };

            let PieUrl = process.env.REACT_APP_BASEURL + "bookings/table_bookings_by_date";
            let res = await postSubmitForm(PieUrl, payload);
            if (res && res.status === 1 && res.data.some(item => item.table_number === row.table_number)) {
                editorProps.onUpdate(+value);
                if (ab[row._id] === undefined) {
                    setAB(prev => prev = { ...prev, [row._id]: price })
                }
                let a = bookedGameDetails;
                price = ab[row._id] ? ab[row._id] : price;
                console.log(value,"va");
                if (+value >= 30) {
                    setUpdateFlag(prev => prev = true)
                    a.forEach(item => {
                        if (item._id === row._id) {
                            if (row.game.name.includes("VIP")) {
                                if (+value > 60) {
                                    let sixtyMin = 60;
                                    let rem = +value - sixtyMin;
                                    item.game.sub_total = ((150 / 60) * sixtyMin) + ((100 / 60) * rem);
                                    item.game.sub_total = +(item.game.sub_total.toFixed(2));
                                    item.game.new_duration = (+item.game.duration) - (+item.game.requested_duration)
                                } else {

                                    item.game.sub_total = (150 / 60) * +value;
                                    item.game.sub_total = +(item.game.sub_total.toFixed(2));
                                    item.game.new_duration = (+item.game.duration) - (+item.game.requested_duration)
                                }
                            } else {
                                item.game.sub_total = (price / 60) * +value;
                                item.game.sub_total = +(item.game.sub_total.toFixed(2))
                                item.game.new_duration = (+item.game.duration) - (+item.game.requested_duration)
                            }
                        }
                    })

                }
                setBookedGameDetails(prev => { prev = [...a]; return prev });

                const startTime = new Date(row.game.table_start_time);
                const minutesToAdd = parseInt(+value, 10);
                if (!isNaN(minutesToAdd)) {
                    const updatedET = new Date(startTime.getTime() + minutesToAdd * 60000);
                    const formattedEndTime = updatedET.toISOString();
                    row.game.table_end_time = new Date(formattedEndTime);
                } else {
                    console.error('Invalid input for minutes');
                }
                findMaxEndTime(bookedGameDetails);
            } else {
                showWarning("Table is busy", "warning")
            }
        }
        else if (+value <= row.game.duration) {
            if (ab[row._id] === undefined) {
                setAB(prev => prev = { ...prev, [row._id]: price })
            }
            let a = bookedGameDetails;
            price = ab[row._id] ? ab[row._id] : price;
            if (+value >= 30) {
                setUpdateFlag(prev => prev = true)
                a.forEach(item => {
                    if (item._id === row._id) {
                        if (row.game.name.includes("VIP")) {
                            if (+value > 60) {
                                let sixtyMin = 60;
                                let rem = +value - sixtyMin;
                                item.game.sub_total = ((150 / 60) * sixtyMin) + ((100 / 60) * rem);
                                item.game.sub_total = +(item.game.sub_total.toFixed(2));
                                item.game.new_duration = (+item.game.duration) - (+item.game.requested_duration)
                            } else {

                                item.game.sub_total = (150 / 60) * +value;
                                item.game.sub_total = +(item.game.sub_total.toFixed(2));
                                item.game.new_duration = (+item.game.duration) - (+item.game.requested_duration)
                            }
                        } else {
                            item.game.sub_total = (price / 60) * +value;
                            item.game.sub_total = +(item.game.sub_total.toFixed(2))
                            item.game.new_duration = (+item.game.duration) - (+item.game.requested_duration)
                        }
                    }
                })

            }
            editorProps.onUpdate(+value);
            setBookedGameDetails(prev => { prev = [...a]; return prev });
            const startTime = new Date(row.game.table_start_time);
            const minutesToAdd = parseInt(+value, 10);
            if (!isNaN(minutesToAdd)) {
                const updatedET = new Date(startTime.getTime() + minutesToAdd * 60000);
                const formattedEndTime = updatedET.toISOString();
                row.game.table_end_time = new Date(formattedEndTime);
            } else {
                console.error('Invalid input for minutes');
            }
            findMaxEndTime(bookedGameDetails);
        }
    };
    const PackageGameDetails = [
        {
            dataField: "game._id",
            hidden: true,
        },
        {
            dataField: "game._id",
            formatter: (cell, row, rowIndex) => {
                // const rowNumber = (+pageNumber - 1) * +pageSize + (+rowIndex + 1);
                const rowNumber = rowIndex + 1
                return rowNumber
            },
            text: props.t("S.N"),
            headerStyle: (colum, colIndex) => {
                return { width: "1%" }
            },
            editable: false
        },
        {
            text: props.t("Game"),
            formatter: showPhotoFormatter,
            dataField: "game.image",
            //sort: false,
            headerStyle: (colum, colIndex) => {
                return { width: "5%" }
            },
            editable: false
        },
        {
            dataField: "game.table_start_time",
            text: "Start Time",
            formatter: (cell, row) => {
                return moment(row.game?.table_start_time).format("HH:mm")
            },
            headerStyle: (colum, colIndex) => {
                return { width: "7%" }
            },
            editorRenderer: (
                editorProps,
                value,
                row,
                column,
                rowIndex,
                columnIndex
            ) => {
                if (row.game?.table_start_time) {
                    return (
                        <input
                            style={{
                                width: "100px",
                                border: "1px solid lightGray",
                                padding: "5px",
                                borderRadius: "5px",
                                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                            }}
                            type="datetime-local"
                            defaultValue={value}
                            onBlur={e => {
                                handleStartTime(e, rowIndex, row, editorProps, value)
                            }}
                            onFocus={e => e.target.select()}
                            autoFocus
                        />
                    )
                } else {
                    return <div style={{ color: "red" }}>Not Available</div>
                }
            },
            editable: true,
        },
        {
            text: props.t("Duration"),
            dataField: "game.duration",
            formatter: (cell, row) => {
                return <p style={{ color: "#E57373", fontWeight: 600, width: "50px" }} >{`${row.game.duration} min`}</p>;
            },
            sort: false,
            editable: true,
            validator: (newValue, row, column) => {
                if (+newValue < 30) {
                    return {
                        valid: false,
                        message: "Minimum Playing Time is 30 Minutes",
                    };
                }
                if (isNaN(newValue)) {
                    return {
                        valid: false,
                        message: "Only numbers are allowed.",
                    };
                }
                if (newValue % 1 != 0) {
                    return {
                        valid: false,
                        message: "Decimals not allowed.",
                    };
                }
                return true;
            },
            editorRenderer: (
                editorProps,
                value,
                row,
                column,
                rowIndex,
                columnIndex
            ) => (
                <input
                    onWheel={preventValueChangeOnScroll}
                    style={{
                        WebkitAppearance: "none",
                        width: "50px",
                        height: "40px",
                        border: "1px solid lightGray",
                        padding: "5px",
                        borderRadius: "5px",
                        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                    }}
                    type="number"
                    defaultValue={value}
                    onBlur={e => {
                        handleDurationChange(e, rowIndex, row, editorProps)
                    }}
                    onFocus={e => e.target.select()} // Retain focus on input field
                    autoFocus
                />
            ),
            headerStyle: (colum, colIndex) => {
                return { width: "5%" }
            },
        },
        {
            text: props.t("End Time"),
            dataField: "game.table_end_time",
            formatter: (cell, row) => {
                return moment(row.game.table_end_time).format("HH:mm")
            },
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { width: "5%" }
            },
            editable: false
        },
        {
            text: props.t("Game Price"),
            dataField: selectedCalendarEvent?.customer_details?.customer_type === "Friend" ? "game.price_for_friend" : selectedCalendarEvent?.customer_details?.customer_type === "Regular" ? "game.price_for_regular" : "game.price_for_vip",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { width: "5%" }
            },
            editable: false
        },
        {
            dataField: "game.sub_total",
            formatter: (cell, row) => row.game.sub_total,
            text: "Sub Total",
            headerStyle: (colum, colIndex) => {
                return { width: "7%" }
            },
            editable: false,
        },
    ]

    const PackageItemDetails = [
        {
            dataField: "item_id",
            hidden: true,
        },
        {
            dataField: "item_id",
            formatter: (cell, row, rowIndex) => {
                // const rowNumber = (+pageNumber - 1) * +pageSize + (+rowIndex + 1);
                const rowNumber = rowIndex + 1
                return rowNumber
            },
            text: props.t("S.N"),
            headerStyle: (colum, colIndex) => {
                return { width: "1%" }
            },
            editable: false
        },
        {
            text: props.t("Image"),
            formatter: showImagePhotoFormatter,
            dataField: "image_url",
            //sort: false,
            headerStyle: (colum, colIndex) => {
                return { width: "5%" }
            },
        },
        {
            text: props.t("Name"),
            dataField: "name",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { width: "5%" }
            },
            editable: false
        },

        {
            dataField: "Section",
            formatter: (cell, row) => row.section,
            text: "section",
            headerStyle: (colum, colIndex) => {
                return { width: "7%" }
            },
            editable: false
        },
        {
            dataField: "available.in_unit",
            text: "Available Units",
            formatter: (cell, row) => {
                return <p >{`${row.available.in_unit} ${row.unit}`}</p>;
            },
            headerStyle: (colum, colIndex) => {
                return { width: "7%" }
            },
            editable: false,

        },
        {
            dataField: "available.in_sub_unit",
            text: "Available Sub Units",
            formatter: (cell, row) => {
                return <p>{(row.sub_unit === null || row.sub_unit === "") ? <Badge className="badge-soft-danger" >No Sub-Unit</Badge> : `${row.available.in_sub_unit} ${row.sub_unit}`}</p>;
            },
            headerStyle: (colum, colIndex) => {
                return { width: "7%" }
            },
            editable: false,
        },
        {
            dataField: "price",
            // formatter: (cell, row) => row.price,
            text: "Selling Price",
            headerStyle: (colum, colIndex) => {
                return { width: "7%" }
            },
            editable: false
        },
        {
            text: props.t("Quantity In-Unit"),
            dataField: "quantity.in_unit",
            formatter: (cell, row) => {
                return <p style={{ color: "#E57373", fontWeight: 600, width: "50px" }} >{`${row.quantity.in_unit} ${row.unit}`}</p>;
            },
            sort: false,
            validator: (newValue, row, column) => {

                if (+newValue > Number(row.available.in_unit)) {
                    return {
                        valid: false,
                        message: "Cannot exceed available units",
                    };
                }
                if (isNaN(newValue)) {
                    return {
                        valid: false,
                        message: "Only numbers are allowed.",
                    };
                }
                if (newValue % 1 != 0) {

                    return {
                        valid: false,
                        message: "Decimals not allowed.",
                    };
                }
                return true;
            },
            editable: false,
            editorRenderer: (
                editorProps,
                value,
                row,
                column,
                rowIndex,
                columnIndex
            ) => (
                <input
                    // ref={inputRef}
                    onWheel={preventValueChangeOnScroll}
                    // className={styles.noSpinner}
                    style={{

                        width: "50px",
                        height: "40px",
                        border: "1px solid lightGray",
                        padding: "5px",
                        borderRadius: "5px",
                        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                    }}
                    type="number"
                    defaultValue={value}
                    // onChange={(e) => { handleQuantityChange(e, rowIndex, row, editorProps);  inputRef.current.blur(); }}
                    onBlur={e => {
                        handleQuantityChange(e, rowIndex, row, editorProps);
                        // editorProps.onUpdate(+e.target.value)

                        // setEnterQtyArr(prev => { prev = { ...prev, [row._id]: row.quantity.in_unit === 0 ? false : true } })
                    }}
                    onFocus={e => e.target.select()} // Retain focus on input field
                    autoFocus
                />
            ),
            headerStyle: (colum, colIndex) => {
                return { width: "5%" }
            },
        },
        {
            text: props.t("Quantity In-Sub-Unit"),
            dataField: "quantity.in_sub_unit",
            formatter: (cell, row) => {
                return <p style={{ color: "#E57373", fontWeight: 600, width: "50px" }} >{(row.sub_unit === "" || row.sub_unit === null) ? <i className="fa fa-ban" /> : `${row.quantity.in_sub_unit} ${row.sub_unit}`}</p>;
            },
            sort: false,

            editable: (cell, row) => ((row.sub_unit === "" || row.sub_unit === null) ? false : false),
            validator: (newValue, row, column) => {

                if (+newValue > Number(((+row.formula) * (+row.available.in_unit)) + (+row.available.in_sub_unit))) {
                    return {
                        valid: false,
                        message: "Cannot exceed available quantity",
                    };
                }
                if (isNaN(newValue)) {
                    return {
                        valid: false,
                        message: "Only numbers are allowed.",
                    };
                }
                if (newValue % 1 != 0) {

                    return {
                        valid: false,
                        message: "Decimals not allowed.",
                    };
                }
                return true;
            },
            editorRenderer: (
                editorProps,
                value,
                row,
                column,
                rowIndex,
                columnIndex
            ) => (
                <input
                    onWheel={preventValueChangeOnScroll}
                    style={{
                        width: "50px",
                        height: "40px",
                        border: "1px solid lightGray",
                        padding: "5px",
                        borderRadius: "5px",
                        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                    }}
                    type="number"
                    defaultValue={value}
                    onBlur={e => {
                        handleSubUnitQuantityChange(e, rowIndex, row, editorProps);

                        // editorProps.onUpdate(+e.target.value)
                        // setEnterQtyArr(prev => { prev = { ...prev, [row._id]: row.quantity.in_unit === 0 ? false : true } })
                    }}
                    onFocus={e => e.target.select()} // Retain focus on input field
                    autoFocus
                />
            ),
            headerStyle: (colum, colIndex) => {
                return { width: "5%" }
            },
        },
        {
            dataField: "sub_total",
            formatter: (cell, row) => row.sub_total,
            text: "Sub Total",
            headerStyle: (colum, colIndex) => {
                return { width: "7%" }
            },
            editable: false
        },
        // {
        //     text: "Action",
        //     dataField: "",
        //     formatter: deleteItemFormatter,
        //     headerStyle: (colum, colIndex) => {
        //         return { width: "7%" }
        //     },
        //     editable: false
        // },
    ]

    // ------------------------------------------------------------------------------------------------

    //Cancel API Call --------------------------------------------------------------------------------

    const handleCancelBooking = async (bookingId, flag) => {
        try {
            const response = await axios.post(
                process.env.REACT_APP_BASEURL + "bookings/cancel",
                { id: bookingId, is_refund_requested: flag === "true" ? true : false },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
                    },
                }
            )

            // console.log("API Response:", response.data)

            if (response.data.status === 1) {

                setConfirmModal(prev => prev = !prev)
                loadAllBookings();


                showBookingDetails("", selectedCalendarEvent._id)
                // swal.fire("Success", response.data.message, "success")
                showWarning(response.data.message, "Success")
                // fetchBookings()
            } else {
                // swal.fire("Error", response.data.message, "error")
                showWarning(response.data.message, "Warning")
            }
        } catch (error) {
            console.error("Error:", error)
            if (error.response) {
                // swal.fire("Error", error.response.data.message, "error")
                showWarning(error.response.data.message, "Warning")
            } else {
                // swal.fire(
                //     "Error",
                //     "An error occurred while canceling the booking.",
                //     "error"
                // )
                showWarning("An Error occurred while canceling the booking", "Warning")
            }
        }
    }
    // ---------------------------------------------------------------------------------------------


    //Check In API Call --------------------------------------------------------------------------------

    const handleCheckInBooking = async bookingId => {


        // Extract the start time
        const startTime = new Date(selectedCalendarEvent.start_time);

        // Get the current time
        const currentTime = new Date();

        // Calculate the difference in minutes
        const timeDifferenceInMinutes = Math.floor((startTime - currentTime) / (1000 * 60));

        // Check if the difference is less than 15 minutes
        if (timeDifferenceInMinutes < 15) {
            try {
                const response = await axios.post(
                    process.env.REACT_APP_BASEURL + "bookings/check_in",
                    { id: bookingId },
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
                        },
                    }
                )

                // console.log("API Response:", response.data)

                if (response.data.status === 1) {
                    loadAllBookings();


                    showBookingDetails("", selectedCalendarEvent._id)

                    showWarning(response.data.message, "Success");

                } else {

                    showWarning(response.data.message, "Warning")
                }
            } catch (error) {
                console.error("Error:", error)
                if (error.response) {

                    showWarning(error.response.data.message, "Warning")
                } else {

                    showWarning("An error occurred while canceling the booking.", "Warning")
                }
            }
        } else {
            // showNotification("Check-In can only be done 15 minutes before start time", "Error");
            showWarning("Check-In can only be done 15 minutes before start time", "Warning")
        }

    }
    console.log(enddTime,"ET");

    const handleUpdateBooking = async (bookingId) => {


        if (updateFlag === false) {
            // showNotification("Can't Update, No changes made", "Error");
            showWarning("Can't Update, No changes made", "Warning")
            return;
        }

        let prevPkgGamesDetails = selectedCalendarEvent?.package_details[0]?.game_details?.length === 0 ? [] : selectedCalendarEvent?.package_details[0]?.game_details?.filter(item => (item?.requested_duration > 0));


        // -----------------------------------------------------------------------------------------
        let totalPrice = 0;
        let itemTotalPrice = 0
        let gameTotalPrice = 0
        if (bookedGameDetails?.length > 0) {

            totalPrice = Number((bookedGameDetails?.reduce((acc, obj) => acc + obj?.game?.sub_total, 0)).toFixed(2))
            gameTotalPrice = Number(totalPrice.toFixed(2));
        }
        if (selectedCalendarEvent?.item_details?.length > 0) {

            totalPrice += selectedCalendarEvent.item_details?.reduce((acc, obj) => acc + obj?.sub_total, 0);
            itemTotalPrice = Number((totalPrice - gameTotalPrice).toFixed(2));
        }
        // -----------------------------------------------------------------------------------------

        let totalTime = 0;

        if (selectedCalendarEvent?.game_details?.length > 0) {

            totalTime = selectedCalendarEvent?.game_details?.reduce((acc, obj) => acc + obj?.game?.duration, 0);
        }

        if (prevPkgGamesDetails?.length > 0) {

            totalTime += prevPkgGamesDetails?.reduce((acc, obj) => acc + obj?.requested_duration, 0)
        }

        // console.log(totalPrice, "total price")
        // console.log(totalTime, "total time")

        let payload = {
            item_details: selectedCalendarEvent.item_details,
            customer_details: selectedCalendarEvent.customer_details,
            booking_id: selectedCalendarEvent._id,
            game_details: bookedGameDetails,
            item_total_price: +itemTotalPrice,
            game_total_price: +gameTotalPrice,
            // total_amount: +selectedCalendarEvent.total_price,
            package_details: selectedCalendarEvent.package_details,
            start_time: startTimeTracker === true ? starttTime : selectedCalendarEvent.start_time,
            end_time: tracker === true ? enddTime : selectedCalendarEvent.end_time,
            booking_status: selectedCalendarEvent.booking_status,
            payment_status: "pending",
            total_price: +totalPrice,
            total_time: +totalTime
        }


        // console.log(payload, "payload");
        // return;
        let url = process.env.REACT_APP_BASEURL + "bookings/update";

        let response = await postSubmitForm(url, payload)
        if (response && response.status === 1) {

            loadAllBookings();
            showBookingDetails("", bookingId)
            setUpdateFlag(prev => prev = false)
            setTracker(false)
            setStartTimeTracker(false)
            showWarning(response.message, "Success")


        } else {

            showWarning(response.message, "Warning")
        }


        // try {
        //     const response = await axios.post(
        //         process.env.REACT_APP_BASEURL + "bookings/update",
        //         payload,
        //         {
        //             headers: {
        //                 Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        //             },
        //         }
        //     )

        //     // console.log("API Response:", response.data)

        //     if (response.data.status === 1) {

        //         loadAllBookings();
        //         showBookingDetails("", bookingId)
        //         setUpdateFlag(prev => prev = false)
        //         // swal.fire("Success", response.data.message, "success")
        //         showWarning(response.data.message, "Success")
        //         // setCheckInOutBtn("Check-Out")
        //         // fetchBookings()
        //     } else {
        //         // setUpdateFlag(prev => prev = false)
        //         // swal.fire("Error", response.data.message, "error")
        //         showWarning(response.data.message, "Warning");
        //     }
        // } catch (error) {
        //     console.error("Error:", error)
        //     if (error.response) {
        //         // swal.fire("Error", error.response.data.message, "error")
        //         showWarning(error.response.data.message, "Warning")
        //     } else {
        //         // swal.fire(
        //         //     "Error",
        //         //     "An error occurred while canceling the booking.",
        //         //     "error"
        //         // )
        //         showWarning("An error occurred while canceling the booking.", "Warning")
        //     }
        // }
    }
    // ---------------------------------------------------------------------------------------------

    function displayItemOrGame(tableName) {
        if (tableName === "game") {
            setItemVisible(prev => prev = false);
            setGameVisible(prev => prev = true);
        } else {
            setItemVisible(prev => prev = true);
            setGameVisible(prev => prev = false);
        }
    }

    useEffect(() => {
        displayItemOrGame("game")
    }, [])


    return <>

        <Modal
            size="xl"
            isOpen={bookingDetailsModal}
            toggle={() => { loadAllBookings(); setBookingDetailsModal(prev => (prev = !prev)) }}
        >

            <ModalHeader
                toggle={() => {setBookingDetailsModal(prev => (prev = !prev)) }}
            >

                {props.t("View-Update Booking")}
                <br />{" "}
                {
                    <>
                        <span className="font-size-15 mr-1 mb-1">
                            {props.t("Status") + ": "}
                        </span>
                        <span
                            // className={`font-size-15 badge-soft-${selectedCalendarEvent?.booking_status === "Cancelled" ? "danger" : selectedCalendarEvent?.booking_status === "Completed" ? "success" : selectedCalendarEvent?.booking_status === "In Process" ? "warning" : selectedCalendarEvent?.booking_status === "Booked" ? "success" : null}`}

                            style={{



                                fontSize: "18px",
                                fontWeight: "bolder",
                                color: `${selectedCalendarEvent?.booking_status === "Cancelled" ? "#f46a6aff" : selectedCalendarEvent?.booking_status === "Completed" ? "#9873AC" : selectedCalendarEvent?.booking_status === "In Process" ? "#f1b44dff" : selectedCalendarEvent?.booking_status === "Booked" ? "#9ec3ffff" : selectedCalendarEvent?.booking_status === "Checked out" ? "#3ec695ff" : null}`,

                                // backgroundColor: `${selectedCalendarEvent?.booking_status === "Cancelled" ? "danger" : selectedCalendarEvent?.booking_status === "Completed" ? "success" : selectedCalendarEvent?.booking_status === "In Process" ? "warning" : selectedCalendarEvent?.booking_status === "Booked" ? "#9ec3ffff" : null}`

                            }}>
                            {selectedCalendarEvent?.booking_status}
                        </span>
                    </>
                }

            </ModalHeader>

            <ModalBody>

                <Row>
                    <Col>
                        <Card>
                            <CardBody>

                                <Row>
                                    <Col lg={8}>
                                        <Row >
                                            <Col lg={6} >
                                                <div style={{ display: "flex", gap: '5px' }}>

                                                    <Label>{props.t("Start Time")}</Label>
                                                    {/* {selectedCalendarEvent?.booking_status === "Booked" && <Button
                                                        style={{
                                                            width: "auto",
                                                            height: "25px",
                                                            // marginTop: "-10px",

                                                            boxShadow:
                                                                "none",
                                                            transition: "transform 0.3s ease",
                                                        }}
                                                        onMouseEnter={e => {
                                                            e.target.style.transform = "scale(1.05)"
                                                        }}
                                                        onMouseLeave={e => {
                                                            e.target.style.transform = "scale(1)"
                                                        }}
                                                        className="btn btn-info btn-sm"
                                                        disabled={!updateStartTimePayload.booking_id}
                                                        onClick={updateStartTime}
                                                    >
                                                        Update
                                                    </Button>} */}

                                                    {startTimeUpdateInputVisibility && selectedCalendarEvent?.booking_status === "Booked" && <Button
                                                        style={{
                                                            width: "auto",
                                                            height: "25px",
                                                            // marginTop: "-10px",

                                                            boxShadow:
                                                                "none",
                                                            transition: "transform 0.3s ease",
                                                        }}
                                                        onMouseEnter={e => {
                                                            e.target.style.transform = "scale(1.05)"
                                                        }}
                                                        onMouseLeave={e => {
                                                            e.target.style.transform = "scale(1)"
                                                        }}
                                                        className="btn btn-sm btn-danger"
                                                        // className="btn btn-danger"
                                                        onClick={() => {
                                                            setStartTimeUpdateInputVisibility(prev => prev = !prev);
                                                            setStartTimeExistingInputVisibility(prev => prev = !prev);
                                                            setUpdateStartTimePayload(prev => prev = {})
                                                        }}

                                                    >
                                                        {props.t("Cancel")}
                                                    </Button>}

                                                </div>
                                                {startTimeExistingInputVisibility && <Input
                                                    // style={{ cursor: "pointer" }}
                                                    // onClick={() => {
                                                    //     if (selectedCalendarEvent?.booking_status === "Booked") {

                                                    //         setStartTimeUpdateInputVisibility(prev => prev = !prev);
                                                    //         setStartTimeExistingInputVisibility(prev => prev = !prev)
                                                    //     }
                                                    // }}
                                                    disabled={true}
                                                    className="form-control"
                                                    name="start_time"
                                                    label="Date Time"
                                                    type="text"
                                                    value={formatTime}
                                                    readOnly={true}
                                                />}
                                                {startTimeUpdateInputVisibility && selectedCalendarEvent?.booking_status === "Booked" && <Input
                                                    className="form-control"
                                                    name="start_time_update"
                                                    label="Date Time"
                                                    disabled={true}
                                                    type="datetime-local"
                                                    readOnly={true}
                                                // value={startTime}
                                                // onChange={e =>
                                                //     getTimeUpdate(e)
                                                // }
                                                />}



                                            </Col>

                                            <Col lg={6} >
                                                <Label>End Time</Label>
                                                <Input
                                                    disabled="true"
                                                    className="form-control"
                                                    name="start_time"
                                                    label="Date Time"
                                                    // type="datetime-local"
                                                    value={endTime}
                                                    onChange={e =>
                                                        setStartTime(prev => (prev = e.target.value))
                                                    }
                                                />
                                            </Col>
                                        </Row>

                                        <Row>

                                            <Col lg={3} style={{ display: 'flex', flexDirection: "column" }}>
                                                <Label style={{ height: "20px", }} ></Label>
                                                <Button
                                                    style={{
                                                        fontSize: "15px",
                                                        fontWeight: "500", width: "auto", color: "black", marginTop: "-0.09rem", boxShadow:
                                                            "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                                                        transition: "transform 0.3s ease",
                                                    }}
                                                    onMouseEnter={e => {
                                                        e.target.style.transform = "scale(1.05)"
                                                    }}
                                                    onMouseLeave={e => {
                                                        e.target.style.transform = "scale(1)"
                                                    }}
                                                    onClick={() => { handleCheckInBooking(selectedCalendarEvent._id) }}

                                                    className={btnClass} disabled={selectedCalendarEvent?.booking_status === "Cancelled" || selectedCalendarEvent?.booking_status === "Completed" || selectedCalendarEvent?.booking_status === "In Process" || selectedCalendarEvent?.booking_status === "Checked out"} >Check-In</Button >
                                            </Col>

                                            <Col lg={3} style={{ display: 'flex', flexDirection: "column" }} >
                                                <Label style={{ height: "20px", }} ></Label>
                                                <Button style={{
                                                    fontSize: "15px",
                                                    fontWeight: "500", width: "auto", color: "black", marginTop: "-0.09rem", boxShadow:
                                                        "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                                                    transition: "transform 0.3s ease",
                                                }}
                                                    onMouseEnter={e => {
                                                        e.target.style.transform = "scale(1.05)"
                                                    }}
                                                    onMouseLeave={e => {
                                                        e.target.style.transform = "scale(1)"
                                                    }} disabled={selectedCalendarEvent.booking_status === "Completed" || selectedCalendarEvent?.booking_status === "Checked out" || selectedCalendarEvent?.booking_status === "Cancelled"} onClick={() => handleUpdateBooking(selectedCalendarEvent._id)} className="btn btn-warning waves-effect waves-light" >{props.t("Update")}</Button>
                                            </Col>

                                            <Col lg={3} style={{ display: 'flex', flexDirection: "column" }} >
                                                <Label style={{ height: "20px" }} ></Label>
                                                <Button style={{
                                                    fontSize: "15px",
                                                    fontWeight: "500", width: "auto", color: "black", marginTop: "-0.09rem", boxShadow:
                                                        "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                                                    transition: "transform 0.3s ease",
                                                }}
                                                    onMouseEnter={e => {
                                                        e.target.style.transform = "scale(1.05)"
                                                    }}
                                                    onMouseLeave={e => {
                                                        e.target.style.transform = "scale(1)"
                                                    }} onClick={() => { setConfirmModal(prev => prev = !prev); }} className="btn btn-danger" disabled={selectedCalendarEvent?.booking_status === "Cancelled" || selectedCalendarEvent?.booking_status === "Completed" || selectedCalendarEvent?.booking_status === "In Process" || selectedCalendarEvent?.booking_status === "Checked out"} >Cancel</Button>
                                            </Col>

                                            <Col lg={3} style={{ display: 'flex', flexDirection: "column" }} >
                                                <Label style={{ height: "20px" }} ></Label>
                                                <Button
                                                    disabled={(selectedCalendarEvent?.booking_status === "Cancelled" || selectedCalendarEvent?.booking_status === "Checked out" || selectedCalendarEvent?.booking_status === "Booked")}
                                                    className="btn btn-success waves-effect waves-light"
                                                    style={{
                                                        fontSize: "15px",
                                                        fontWeight: "500", width: "auto", color: "black", marginTop: "-0.09rem",
                                                        boxShadow:
                                                            "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                                                        transition: "transform 0.3s ease",
                                                        whiteSpace: "nowrap",
                                                        overflow: 'hidden',
                                                        textOverflow: "ellipsis",
                                                    }}
                                                    onMouseEnter={e => {
                                                        e.target.style.transform = "scale(1.05)"
                                                    }}
                                                    onMouseLeave={e => {
                                                        e.target.style.transform = "scale(1)"
                                                    }}

                                                >
                                                    <Link style={{
                                                        color: "white",

                                                    }} to="/check-out"
                                                        onClick={() => localStorage.setItem('instantcheckout', JSON.stringify(selectedCalendarEvent))}
                                                    >

                                                        {props.t("Instant Checkout")}
                                                    </Link>
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col>
                                        <Label>Remarks</Label>
                                        <Input
                                            readOnly={true}
                                            type="textarea"
                                            value={selectedCalendarEvent.remarks}
                                        />
                                    </Col>
                                </Row>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <br /> <br />
                {/* <Row>
                    <Col  >
                        <Label style={{ fontWeight: "bold" }}>Customer Details:</Label>

                    </Col>
                </Row>

                <Row lg={4}>
                    <Col>

                        <div className="scrollable-details-customer" style={{ borderRadius: "5px" }}>
                            <h5 className="modal-heading">
                                <span style={{ fontWeight: "bold", color: "#556ee6" }}>Name: &nbsp;</span> <span style={{ color: "#fa395d" }}>{selectedCalendarEvent.customer_details.name}</span>
                            </h5>
                            <h5 className="modal-heading">
                                <span style={{ fontWeight: "bold", color: "#556ee6" }}>Mobile: &nbsp;</span> <span style={{ color: "#fa395d" }}>{selectedCalendarEvent.customer_details.mobile}</span>
                            </h5>
                            <h5 className="modal-heading">
                                <span style={{ fontWeight: "bold", color: "#556ee6" }}>Type: &nbsp;</span> <span style={{ color: "#fa395d" }}>{selectedCalendarEvent.customer_details.customer_type}</span>
                            </h5>
                            
                        </div>
                    </Col>
                </Row> */}

                <Row >
                    <Col>

                        {/* <Card>
                            <CardBody> */}
                        <CardTitle>
                            CUSTOMER DETAILS
                        </CardTitle>
                        <ToolkitProvider
                            keyField="_id"
                            columns={customerDetailsColumn}
                            // data={bookedItemDetails?.length > 0 ? bookedItemDetails : []}
                            data={[selectedCalendarEvent.customer_details] || []}
                            search
                        >
                            {propst => (
                                <div>
                                    {/* <AvForm>
                                                <Row>
                                                    <Col
                                                        lg={4}
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "flex-start",
                                                        }}
                                                    >
                                                        <SearchBar
                                                            {...propst.searchProps}
                                                            lg={4}
                                                        />
                                                    </Col>
                                                </Row>
                                            </AvForm> */}
                                    <BootstrapTable
                                        {...propst.baseProps}
                                        noDataIndication={props.t("No data to display.")}
                                        striped
                                        hover
                                        condensed
                                        cellEdit={cellEditFactory({ mode: "click", blurToSave: true })}

                                    />
                                </div>
                            )}
                        </ToolkitProvider>
                        {/* </CardBody>
                        </Card> */}
                    </Col>
                </Row>

                <br />

                {/* <Accordion open={open} toggle={toggle}>
                    <AccordionItem>
                        <AccordionHeader targetId="1">Item Details</AccordionHeader>
                        <AccordionBody accordionId="1"> */}

                <Row>
                    <Col>
                        {/* <Card>
                            <CardBody> */}
                        <CardTitle>GAMES</CardTitle>
                        <ToolkitProvider
                            keyField="_id"
                            columns={PackageGameDetails}
                            data={bookedGameDetails?.length > 0 ? bookedGameDetails : []}
                        // data={selectedCalendarEvent.game_details || []}
                        // search
                        >
                            {propst => (
                                <div>
                                    {/* <AvForm>
                                                <Row>
                                                    <Col
                                                        lg={4}
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "flex-start",
                                                        }}
                                                    >
                                                        <SearchBar
                                                            {...propst.searchProps}
                                                            lg={4}
                                                        />
                                                    </Col>
                                                </Row>
                                            </AvForm> */}
                                    <BootstrapTable
                                        {...propst.baseProps}
                                        noDataIndication={props.t("No data to display.")}
                                        striped
                                        hover
                                        condensed
                                        cellEdit={cellEditFactory({ mode: "click", blurToSave: true })}
                                    // selectRow={selectRowItems}
                                    // rowStyle={rowStyle}
                                    // pagination={paginationFactory(options)}
                                    />
                                </div>
                            )}
                        </ToolkitProvider>
                        {/* </CardBody>
                        </Card> */}
                    </Col>
                </Row>

                <br />
                {/* </AccordionBody>
                    </AccordionItem> */}

                {/* <AccordionItem>
                        <AccordionHeader targetId="2">Game Details</AccordionHeader>
                        <AccordionBody accordionId="2"> */}

                <Row>
                    <Col>

                        {/* <Card>
                            <CardBody> */}
                        <CardTitle>
                            ITEMS
                        </CardTitle>
                        <ToolkitProvider
                            keyField="item_id"
                            columns={PackageItemDetails}
                            // data={bookedItemDetails?.length > 0 ? bookedItemDetails : []}
                            data={selectedCalendarEvent.item_details || []}
                        // search
                        >
                            {propst => (
                                <div>
                                    {/* <AvForm>
                                                <Row>
                                                    <Col
                                                        lg={4}
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "flex-start",
                                                        }}
                                                    >
                                                        <SearchBar
                                                            {...propst.searchProps}
                                                            lg={4}
                                                        />
                                                    </Col>
                                                </Row>
                                            </AvForm> */}
                                    <BootstrapTable
                                        {...propst.baseProps}
                                        noDataIndication={props.t("No data to display.")}
                                        striped
                                        hover
                                        condensed
                                        cellEdit={cellEditFactory({ mode: "click", blurToSave: true })}

                                    />
                                </div>
                            )}
                        </ToolkitProvider>
                        {/* </CardBody>
                        </Card> */}
                    </Col>
                </Row>

                <br />
                {/* </AccordionBody>
                    </AccordionItem> */}

                {/* <AccordionItem>
                        <AccordionHeader targetId="3">Package Details</AccordionHeader>
                        <AccordionBody accordionId="3"> */}

                <Row>
                    <Col>
                        {/* <Card>

                            <CardBody> */}
                        <CardTitle>
                            CUSTOMER PACKAGE
                        </CardTitle>

                        <AvForm>
                            <Row >
                                <Col lg={4}>

                                    <AvField
                                        label={props.t("Select")}
                                        className="form-control"
                                        name="selectItemOrGame"
                                        type="select"
                                        // validate={
                                        //   { required: false }
                                        // }
                                        // errorMessage="Please Select Branch"
                                        onChange={(e) => displayItemOrGame(e.target.value)}
                                    >
                                        <option value="game">{props.t("Game")}</option>
                                        <option value="item">{props.t("Item")}</option>


                                    </AvField>
                                </Col>
                            </Row>
                        </AvForm>
                        <Row>


                            <Col>


                                {gameVisible && <Row>
                                    <Col>
                                        <ToolkitProvider
                                            keyField="game_id"
                                            columns={addPackageGameDetailsColumn}
                                            data={selectedCalendarEvent?.package_details[0]?.game_details || []}
                                        // search
                                        >
                                            {propst => (
                                                <div>
                                                    {/* <AvForm>
                                                                <Row>
                                                                    <Col
                                                                        lg={4}
                                                                        style={{
                                                                            display: "flex",
                                                                            alignItems: "flex-start",
                                                                        }}
                                                                    >
                                                                        <SearchBar
                                                                            {...propst.searchProps}
                                                                            style={{ width: "340px" }}
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                            </AvForm> */}
                                                    <BootstrapTable
                                                        {...propst.baseProps}
                                                        noDataIndication={props.t("No data to display.")}
                                                        striped
                                                        hover
                                                        condensed
                                                        // selectRow={selectRowGames}
                                                        cellEdit={cellEditFactory({ mode: "click", blurToSave: true })}
                                                    // pagination={paginationFactory(options)}
                                                    />
                                                </div>
                                            )}
                                        </ToolkitProvider>
                                    </Col>
                                </Row>}

                                {itemVisible &&
                                    <Row>
                                        <Col>

                                            <ToolkitProvider
                                                keyField="item_id"
                                                columns={addPackageItemDetailsColumn}
                                                data={selectedCalendarEvent?.package_details[0]?.item_details || []}
                                                search
                                            >
                                                {propst => (
                                                    <div>
                                                        {/* <AvForm>
                                                                    <Row>
                                                                        <Col
                                                                            lg={4}
                                                                            style={{
                                                                                display: "flex",
                                                                                alignItems: "flex-start",
                                                                            }}
                                                                        >
                                                                            <SearchBar
                                                                                {...propst.searchProps}
                                                                                lg={4}
                                                                            />
                                                                        </Col>
                                                                    </Row>
                                                                </AvForm> */}
                                                        <BootstrapTable
                                                            {...propst.baseProps}
                                                            noDataIndication={props.t("No data to display.")}
                                                            striped
                                                            hover
                                                            condensed
                                                            // selectRow={selectRowItems}
                                                            // onSelect={handleRowSelectItems}
                                                            // rowStyle={rowStyle}
                                                            cellEdit={cellEditFactory({ mode: "click", blurToSave: true })}
                                                        // pagination={paginationFactory(options)}
                                                        />
                                                    </div>
                                                )}
                                            </ToolkitProvider>
                                        </Col>
                                    </Row>
                                }
                            </Col>

                        </Row>

                        {/* </CardBody>
                        </Card> */}
                    </Col>
                </Row>


                {/* </AccordionBody>
                    </AccordionItem>
                </Accordion> */}

            </ModalBody>
        </Modal >

        {/* Cancellation confirm modal */}
        < Modal
            isOpen={confirmModal}
            toggle={() => setConfirmModal(prev => prev = !prev)}
            className="d-flex align-items-center justify-content-center"
        >
            <ModalHeader toggle={() => setConfirmModal(prev => prev = !prev)}></ModalHeader>
            <ModalBody>
                {/* <Input
                    type="textarea"
                    placeholder="Enter remark"
                    value={remark}
                    onChange={e => setRemark(e.target.value)}
                /> */}
                <h5>Do you want refund ?</h5>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={() => { handleCancelBooking(selectedCalendarEvent._id, "true") }} >
                    Yes
                </Button>
                <Button color="danger" onClick={() => { handleCancelBooking(selectedCalendarEvent._id, "false") }} >
                    No
                </Button>
            </ModalFooter>
        </Modal >
    </>

}


export default withRouter(connect(null, {})(withTranslation()(ViewUpdateBooking)))